import React from "react"
import Image from "gatsby-image"
import { navigate } from "gatsby"
import {
  CardActionArea,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
} from "@material-ui/core"
import AppsIcon from "@material-ui/icons/Apps"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  card: {
    height: "100%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: "10px",
  },
})

const CategoryCard = ({ cat, topicCounts }) => {
  const classes = useStyles()

  const ActionWrapper = ({ children }) => {
    if (topicCounts === 0) {
      return <>{children}</>
    } else {
      return (
        <CardActionArea
          style={{ height: "100%" }}
          onClick={() => navigate(`/knowledge/${cat.slug}`)}
        >
          {children}
        </CardActionArea>
      )
    }
  }

  return (
    <ActionWrapper>
      <Card key={cat.slug} className={classes.card}>
        <CardHeader
          title={cat.categoryHeader}
          subheader={cat.categorySubheader}
          titleTypographyProps={{ variant: "h6", noWrap: true }}
          subheaderTypographyProps={{ variant: "subtitle2", noWrap: true }}
        />
        <CardMedia>
          <Image
            fluid={cat.categoryImage.childImageSharp.fluid}
            style={{ height: "160px" }}
          />
        </CardMedia>
        <CardContent className={classes.cardContent}>
          <AppsIcon className={classes.icon} />
          <Typography variant="body2">
            <strong>
              {topicCounts === 1 ? "1 Topic" : `${topicCounts} Topics`}
            </strong>
          </Typography>
        </CardContent>
      </Card>
    </ActionWrapper>
  )
}

export default CategoryCard
