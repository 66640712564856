import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import TopicsMain from "../components/topics/topicsMain"
import Categories from "../components/topics/categories"

export const data = graphql`
  {
    allStrapiCategory {
      nodes {
        categoryHeader
        categoryImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        categorySubheader
        slug
      }
    }
    allStrapiTopic {
      nodes {
        category {
          categoryHeader
        }
        stages {
          stageCKD
        }
      }
    }
  }
`

const TopicsPage = ({
  data: {
    allStrapiCategory: { nodes: categories },
    allStrapiTopic: { nodes: topicsCategories },
  },
}) => {

  return (
    <Layout activePage="knowledge">
      <SEO title="Topics" />
      
      <TopicsMain>
        <Categories
          categories={categories}
          topicsCategories={topicsCategories.map(c => {
            return {
              category: c.category.categoryHeader,
              stages: c.stages.map(s => s.stageCKD),
            }
          })}
        />
      </TopicsMain>
    </Layout>
  )
}

export default TopicsPage
