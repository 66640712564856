import React, { useContext } from "react"
import CategoryCard from "./categoryCard"
import { Grid, Typography } from "@material-ui/core"
import { AppContext } from "../../context/appContext"

const Categories = ({ categories, topicsCategories }) => {
  const { CKDStage } = useContext(AppContext)

  const getCountsByCategory = category => {
    const stageFilteredTopics = topicsCategories.filter(topic =>
      topic.stages.includes(CKDStage)
    )
    return stageFilteredTopics.filter(topic => topic.category === category)
      .length
  }

  const getTotalCounts = () => {
    const stageFilteredTopics = topicsCategories.filter(topic =>
      topic.stages.includes(CKDStage)
    )
    return stageFilteredTopics.length
  }

  return (
    <>
      <Typography
        variant="overline"
        paragraph
      >{`${getTotalCounts()} Topics in ${
        categories.length
      } Categories`}</Typography>
      <Grid container spacing={4}>
        {categories.map(category => {
          return (
            <Grid key={category.slug} item xs={12} sm={6} lg={4}>
              <CategoryCard
                cat={category}
                topicCounts={getCountsByCategory(category.categoryHeader)}
              />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default Categories
